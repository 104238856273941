.card {
	position: relative;
	overflow: visible;
	padding: var(--mantine-spacing-xl);
	padding-top: calc(var(--mantine-spacing-xl) * 1.5 + rem(20px));
}

.icon {
	position: absolute;
	top: rem(-20px);
	left: calc(50% - rem(30px));
}

.title {
	font-family: Greycliff CF, var(--mantine-font-family);
	line-height: 1;
}
