@import "variables";

@import "mixin";

.cursor-pointer {
	cursor: pointer;
}

.cursor-pin {
	cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACoElEQVR4nO2av2sUQRiGH8MRRMJhIZJCJMQQwiF2gqSQIIdFCisL/4ggIZWFf4BNSHGVZQqxSBXEQi3EQsUmYCEqIiIWMYVKCImGIFp8t9zm7nZvb+fbmZvdeWDhfu3c+747O9/MMhAIBAKBQMAD1oDzrkW4og4cAp+paAjXgX/tQzWEMa2GCmYm9noaeI5SCL4EMNH1Xi0EXwL40+cz1Z4w6tygMwZ0H0Zjgi89oJbyXel7QhP4TXIPKKQ6jApZzZcyhGHNlyqEvOZLEYKpea9D0DLvZQja5qPjGSnl/kQBRqbbZi4Ds8AZYBw4AL4DH4DXwFPgZ/ucJvAIOJnzP+8BX4ELyLT5APgCvAK2crY5NHPAG7JfmSPE9G3MrvxdG+ay0qIjbAeZwk4Bp5A1fQNYBt6S3/DImo+Ih7BO5/5rAE+Av5TYfER3CAvAHjrGR958RDwEravujfmIeAiVMx+hFYKX5iNMQ/DafMQ6+czfL1KUrSdCp4H5nOde1BTigjFgE7NbYKanVY+4g/kAuGxdtRLXkDm/aQAbtoVrcA5ZD2iUwHeWtRszjix5BxnbBx5n+N0vu/LNyVL3PwGXMv7+0KJ2Y24x2PwmUhrjpIWwb0O4Bg3SV31HSFVIIimEb8VJ1qMOfCTZ/A5SFQbRL4QXBehVZ4Nk8y+RqpCV7hDWVJUWwArJ5ltIVRiWeAiLOjKL4Sr9Jzt7yIBoQgv4Qf6nxYUzCWzTa/49MiBqsKDUjjo1ZHDqN22tO9RljVV6S9yKU0UWuclx89vIWFAJ5oBdjtfoSaeKLDKBrMwi86uk798pHQ8R47vIbVApluiszWcda7HOPLIkfUDvrs3ScxbZabHkWogLasjz/CuuhbiiifSAyuLLVtpAIBAIBHLwHw3vJfVa2KJsAAAAAElFTkSuQmCC')
	, auto;
}

.overflow-ellipsis {
	@include overflow-ellipsis();
	width: 135px;
	display: inline-block;
	vertical-align: middle;
}

.btn.btn-danger {
	color: $white;
}

.tooltip {
	position: fixed;
	opacity: 1 !important;
}

.carousel-control-prev-icon, 
.carousel-control-next-icon {
	width: 1rem;
	height: 1rem;
}

.btn.with-icon {
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;

	&.start {
		i {
			margin-right: 0.25rem;
		}
	}
	&.end {
		i {
			margin-left: 0.25rem;
		}
	}

}
