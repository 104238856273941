@import 'scss/theme';

.news {

	&.list-group {

		.list-group-item {
			padding: 0.25rem;
			display: flex;
			align-content: center;
			cursor: pointer;

			> div {
				display: flex;
				align-items: center;
				padding: 0.4rem 0;
			}

			div:first-child {
				border-left: 0.2rem solid;
			}
		}
	}

	.list-group-item-warning {
		color: $body-color;
		background-color: map-get($theme-colors, 'warning');
	}
}

