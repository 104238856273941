// scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #f3f6f9;
$gray-300: #f0f0f0;
$gray-400: #eaeaea;
$gray-500: #e5e4e3;
$gray-600: #bfbfbf;
$gray-700: #6c757d;
$gray-800: #676873;
$gray-900: #41424c;
$black:    #000;
// scss-docs-end gray-color-variables

