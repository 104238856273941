@import 'scss/theme';

.public-layout {

	height: 100%;
	display: flex;
	flex-direction: column;

	main {
		background-color: $white;
		flex: 1 0 auto;
	}

	footer {
		flex-shrink: 0;
	}
}
