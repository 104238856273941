@import 'scss/theme';

.notes-table {

	.content {
		max-width: 30vw;
	}

	&.table-hover .table-active {

		&:hover {
			background-color: lighten($warning, 35%);

			> td,
			> th {
				background-color: lighten($warning, 35%);
			}
		}
	}

}
