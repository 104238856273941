@import 'scss/theme';

.integrated-provider {
	min-height: 300px;
	height: 100%;
	font-size: 0.9rem;

	.badge {
		position: absolute;
		top: 15px;
		right: 0;
		line-height: 0.5;

		.bif {
			vertical-align: middle;
			margin-right: 6px;
		}
	}

	.badge-pill {
		padding: 0.5em $badge-pill-padding-x;
	}

}

.card-img,
.card-img-top,
.card-img-bottom {
	width: 50%;
	margin: 2.8rem auto 1.25rem;
}

.card-footer {
	background-color: $card-bg;
	border-top: 0;
	padding: 0 $card-spacer-x 1.35rem;
}
