@import "scss/theme";

.conversation-details {

	flex: 1;
	overflow-wrap: anywhere;
}



@include media-breakpoint-down(lg) {

	.conversation-details {

		background-color: map-get($theme-colors, 'secondary') !important;
		max-height: 400px;
		overflow: auto;
	}
}
