@mixin reset-anchor() {
	outline: 0;
	color: inherit;
	text-decoration: none;

	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

@mixin reset-button() {
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	padding: 0;
	margin: 0;
	overflow: visible;
	color: inherit;
	text-align: inherit;
	cursor: pointer;
	background: none;
	border: none;
	outline: 0;

	&::-moz-focus-inner {
		padding: 0;
		border: none;
	}

	&:focus {
		outline: 0;
	}
}

@mixin reset-list() {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin reset-paragraph() {
	margin: 0;
}

@mixin reset-range-track() {
	@include range-track() {
		background: none;
		border: none;
		appearance: none;
	}
}

@mixin reset-range-thumb() {
	@include range-thumb() {
		background: none;
		border: none;
		appearance: none;
	}
}

@mixin reset-range() {
	background: none;
	border: none;
	outline: 0;
	appearance: none;

	&::-moz-focus-inner,
	&::-moz-focus-outer {
		border: none;
	}

	@include reset-range-track();
	@include reset-range-thumb();
}
