@import 'scss/theme';

.auth-sidebar-layout {

	.aside {
		flex: 0 0 auto;
	}

	.feeds-container {

		display: flex;
		padding: map-get($spacers, 200);
		background-color: map-get($theme-colors, 'secondary');
		width: 300px;
	}
}

@include media-breakpoint-down(lg) {

	.auth-sidebar-layout {
		.feeds-container {
			display: none;
		}
	}
}

@include media-breakpoint-up(xl) {

	.auth-sidebar-layout {
		.feeds-container {
			width: 360px;
		}

		.details-page {
			width: 500px;
		}
	}
}

@include media-breakpoint-up(xxl) {

	.auth-sidebar-layout {
		.feeds-container {
			width: 540px;
		}

		.details-page {
			width: 840px;
		}
	}
}
