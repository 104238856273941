
.react-select__container {

	.react-select__control {
		&:hover {
			border-color: $primary;
		}
		&--is-focused {
			box-shadow: none;
			border-color: $primary;
		}
	}

	.react-select__option.react-select__option--is-selected {
		background-color: $primary;
		&:active {
			background-color: $primary;
		}
	}

	.react-select__option {

		&:active {
			background-color: lighten($primary, 30%);
		}

		&--is-selected {
			background-color: $primary;
			&:active {
				background-color: $primary;
			}
		}

		&--is-focused {
			background-color: lighten($primary, 40%);
			&:active {
				background-color: lighten($primary, 30%);
			}
		}
	}
}
