
.conversation-tabs-container {
  display: flex;
  flex: 1 auto;
  overflow: auto;

  .active.tab-pane {
    width: 100%;

    &.ai-assistant {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
