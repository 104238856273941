.summuryPayment {
	padding: 2rem 3rem;

	dl {
		display: flex;
		flex-direction:row;
		flex-wrap: wrap;
		margin-bottom: 0;
	}

	dt, dd {
		flex-basis:50%;
		border-top: 1px solid grey;
		padding: 0.875rem;
	}

	dt {
		font-weight: normal;
	}

	dd {
		text-align: right;
		margin-bottom: 0;
		font-weight: bold;
	}
}