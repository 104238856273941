
.ai-assistant {

  overflow: auto;

  &-content {
    overflow: auto;
    flex: 1 auto;
		display: flex;
		align-items: flex-end;
  }

  &-footer {
    flex: 0 auto;
		margin-top: 0.75rem;
  }
}
