@import "scss/theme";

$size: 2px;
$color: $dark;

.burger-wrapper {
	cursor: pointer;
	width: $size*10;
	height: $size*8;

	.hamburger {
		background: $color;
		height: $size;
		position: relative;
		transition: background 10ms 200ms ease;
		transform: translateY($size*4);

		&:before, &:after {
			transition: top 200ms 250ms ease,
			transform 200ms 50ms ease;
			position: absolute;
			background: $color;
			width: 100%;
			height: $size;
			content: '';
		}
		&:before {
			top: $size*-3;
		}
		&:after {
			top: $size*3;
		}
	}

	&-expanded {
		.hamburger {
			background: transparent;
			&:after, &:before {
				transition: top 200ms 50ms ease,
				transform 200ms 250ms ease;
				top: 0;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}
	}
}
