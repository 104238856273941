.toast-container {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999;
  top: 10px;

  .toast {
    min-width: 300px
  }
}
