
.dropdown {

	border: 1px solid var(--popover-border-color);

	@mixin light {
		--popover-border-color: var(--mantine-color-dark-0);
	}

}

