@import 'scss/theme';

$active-border-size: 0.25rem;

.offcanvas {

	.sidebar {
		overflow-x: hidden;
		overflow-y: auto;
	}
}

.sidebar {

	display: flex;
	flex-direction: column;
	height: 100%;
	//position: relative;
	background-color: map-get($theme-colors, 'secondary');
	overflow-x: hidden;

	.nav {

		flex-wrap: nowrap;
		flex: 1 0 auto;
	}

	.nav-item {
		padding: 0.2rem 0.4rem;

		.bif-chevron-thin-up,
		.bif-chevron-thin-down {
			&::before {
				font-size: 0.5rem;
				vertical-align: middle;
			}
		}

		.nav {

			display: none;

			.nav-link {
				margin-left: 1.7rem;
				//color: map-get($theme-colors, 'secondary');
			}

			img {
				display: none;
			}
		}
	}

	.nav-item:hover {
		background-color: $light;
	}

	.nav-item.active {

		background-color: $white;

		> a {
			border-left: $active-border-size solid $danger;
		}

		img {
			margin-left: -$active-border-size;
		}

		.nav {

			display: flex;
		}
	}

	.nav-link {

		display: flex;
		align-items: center;
		padding: 0.3rem 0.6rem;
		color: map-get($theme-colors, 'dark');

		img {
			height: 24px;
			width: 32px;
		}
	}

	.logo-container, .avatar-container {

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.logo-container {
		min-height: 100px;
	}

	.avatar {
		box-shadow: 0px 10px 20px 1px rgb(0 0 0 / 65%);
		display: inline-flex;
	}

	.dropdown {
		position: static;
	}

	.search-bar {
		border: 0;
		box-shadow: none;
	}

	&-expanded {

		width: 300px;
		padding: 0 map-get($spacers, 200);

		.logo-container, .avatar-container {
			padding: 10px;
		}

		.logo {
			height: 60px;
			max-width: 100%;
		}

		.avatar {
			width: 110px !important;
			height: 110px !important;
		}

		.dropdown {
			order: 0;
			align-self: end;
		}

		> .nav, .full-width {
			margin: 0 -2rem;
		}

		.collapse-icon {
			display: block;
		}

		.expand-icon {
			display: none;
		}
	}

	&-collapsed {

		width: 80px;
		padding: 0 map-get($spacers, 50);

		.collapsed-hidden {
			display: none;
		}

		.logo {

			transform: rotate(-90deg);
			height: 40px;
		}

		.avatar {
			width: 64px !important;
			height: 64px !important;
		}

		.dropdown {
			order: 2;
			margin-top: map-get($spacers, 75);
		}

		.nav-link {

			justify-content: center;
		}

		> .nav, .full-width {
			margin: 0 -0.5rem;
		}

		.collapse-icon {
			display: none;
		}

		.expand-icon {
			display: block;
		}
	}
}
