
.sortable-col {
	cursor: pointer;
	text-decoration: underline;
	line-height: 1;

	.sort-up,
	.sort-down {
		&::before {
			font-size: 0.25rem;
		}
	}

	.sort-up {
		line-height: normal;
	}
	.sort-down {
		vertical-align: inherit;
	}
}
