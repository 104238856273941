
.listItem {
	cursor: default;
}

.listItemIcon {
	margin-inline-end: var(--mantine-spacing-xxs);
}

.text {
	cursor: pointer;

	@mixin hover {
		text-decoration: underline;
	}


}

