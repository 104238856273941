.root {

	overflow: visible;
	font-weight: 400;

	/* for button as link */
	@mixin hover {
		&:not([data-loading]):not(:disabled):not([data-disabled]) {

			&[data-hover-color-dark] {
				color: var(--mantine-color-black);
			}

			&:not([data-hover-color-dark]) {
				color: var(--mantine-color-white);
			}

		}
	}

	&[data-variant='outline-filled'] {

		@mixin hover {
			&:not([data-loading]):not(:disabled):not([data-disabled]) {

				&[data-hover-color-dark] {
					--_button-color: var(--mantine-color-black);
				}

				&:not([data-hover-color-dark]) {
					--_button-color: var(--mantine-color-white);
				}

			}
		}

	}

}
