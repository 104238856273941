
.underline-wavy {
	text-decoration: underline;
	text-decoration-thickness: 0.2rem;
	text-decoration-style: wavy;
	display: inline-block;
	line-height: 0;
}

.underline-solid {
	text-decoration: underline;
	text-decoration-thickness: 0.2rem;
	text-decoration-style: solid;
	display: inline-block;
	line-height: 0;
}
