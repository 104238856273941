@import "scss/theme";

.pricing-plan-item {

	cursor: pointer;
	min-height: 100px;

	&.selected-plan {
		outline: map-get($theme-colors, 'warning') solid 4px;
	}

	.pricing {
		display: flex;
		text-align: left;
		width: 160px;
		height: 64px;

		.price {
			flex: 0 92px;
		}

		.details {
			flex: 1 auto;
		}
	}
}
