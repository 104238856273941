@import 'scss/theme';

.list-group.activities {

	.list-group-item {
		padding: 0.25rem;
		display: flex;
		align-content: center;
		cursor: pointer;

		> div {
			display: flex;
			align-items: center;
			padding: 0.4rem 0;
		}

		div:first-child {
			border-left: 0.2rem solid;
		}
	}
}
