@import 'scss/theme';

.separator {
	position: relative;
	text-align: center;

	&-line {
		position: absolute;
		top: 50%;
		width: 100%;
		border-bottom: 1px solid transparent;
	}

	&-text {
		display: inline-block;
		position: relative;
		padding: 0 1rem;
		background: $white;
		font-weight: bold;
		text-transform: uppercase;
		user-select: none;
	}
}
