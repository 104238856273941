@import 'scss/theme';

.analyses {

	overflow: auto;

	.transcripts-chat-row {

		.title {
			height: 50px;
		}
	}
}

.transcripts-chat {
	display: flex;
	flex-direction: column;

	&-row {
		max-width: 80%;
		display: flex;
		margin-top: map-get($spacers, 50);
		scroll-margin-top: map-get($spacers, 50);

		.transcript {
			border-radius: $border-radius-lg;
			border: map-get($border-widths, 2) solid map-get($theme-colors, 'secondary');
			padding: 0.5rem 1rem;

			.highlighted {
				font-weight: bold;
			}
		}

		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.name {
				font-weight: bold;
				flex: 1 1 auto;
				margin: 0 map-get($spacers, 50);
			}
		}
	}

	&-row.highlighted {

		.transcript {
			border-color: map-get($theme-colors, 'warning');
		}
	}

	&-left {

		.transcript {
			background-color: map-get($theme-colors, 'secondary');
			border-top-left-radius: 0;
		}

		.content {
			margin-left: 0.5rem;
		}
	}

	&-right {
		width: 100%;
		align-self: flex-end;
		flex-direction: row-reverse;

		.title {
			flex-direction: row-reverse;

			.name {
				text-align: end;
			}
		}

		.transcript {
			text-align: end;
			border-top-right-radius: 0;
		}

		.content {
			margin-right: 0.5rem;
		}
	}



	.faded {
		opacity: 0.5;
		filter: blur(0.8px);
	}
}

.transcripts-chat + .transcripts-chat {
	margin-top: map-get($spacers, 300);
}
