
.label {
    /*font-size: var(--mantine-font-size-sm);*/
}

.item {
    /*font-size: var(--mantine-font-size-md);*/

    &:hover {
        color: var(--menu-item-color, var(--mantine-color-text));
    }
}
