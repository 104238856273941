@import "scss/theme";

.audio-player {
	display: flex;
	flex-flow: row wrap;
	color: $white;

	.wavesurfer {}

	.controls {
		text-align: center;

		.btn-icon.btn-sm {
			font-size: .875rem;
			padding: 0.35rem;
			width: 2rem;
			height: 2rem;
		}

		.btn-icon.btn-lg {
			font-size: 1.25rem;
			padding: 0.8rem;
			width: 3rem;
			height: 3rem;
		}

		.btn-icon {
			border-radius: 50%;
			font-size: 1rem;
			line-height: 1;
			padding: 0.6rem;
		}

	}

	.title {
		max-width: 100%;
	}

	.time {
		text-align: center;
		font-size: $small-font-size;
		white-space: nowrap;
	}

	.actions {}
}

.audio-player > * {
	flex: 1 100%;
}

@include media-breakpoint-up(lg) {

	.audio-player {

		.side-data {
			flex: 1 0 0;
		}

		.controls {
			flex: 1 auto;
		}
		.title {
			flex: 2 0;
			max-width: 40%;
		}
		.time {
			text-align: end;
			font-size:  map-get($font-sizes, 6);
		}

		.title {
			order: 1;
		}
		.controls {
			order: 2;
		}
		.time {
			order: 3;
		}
		.actions {
			order: 4;
		}
		.wavesurfer {
			order: 5;
		}
	}
}


.audio-player {

	.markers-track {

		position: relative;
		margin-top: 20px;
		background-image: radial-gradient(circle at 0.4px 0.4px, $white 1px, transparent 0);
		background-size: 6px;
		height: 2px;
	}

	.dropdown-actions {
		width: 300px;
		@include box-shadow($box-shadow);
	}

	//.volume-control {
	//	outline: none;
	//	appearance: none;
	//	height: 4px;
	//	background: #eaeaea;
	//	border-radius: 5px;
	//	background-image: linear-gradient(#198754, #198754);
	//	background-size: 70% 100%;
	//	background-repeat: no-repeat;
	//}
}

.RecordingPage {
	$time-display-font-size: 1.2rem;
	$control-color: $gray-900;
	$control-hover-bg-color: $gray-100;
	$control-font-size: 1rem;
	$control-padding: 0.75rem;

	&-header {
		align-items: center;
		display: inline-block;
		min-height: 38px;

		@include media-breakpoint-up(md) {
			display: flex;
			margin-bottom: 1rem;
		}
	}

	&-info {
		flex: 1;

		h1, h2, h3, h4, h5, h6 {
			margin: 0;
		}

		.input-group-append button {
			line-height: 0;
		}
	}

	&-summary {
		@include reset-paragraph();
	}

	&-time-display {
		padding: 0 $control-padding;
		font-size: $time-display-font-size;
		color: $gray-500;
	}

	&-time-current {
		font-weight: bold;
		color: $dark;
	}

	&-controls-top {
		display: flex;
		align-items: center;
	}

	&-control {
		@include reset-button();

		padding: $control-padding;
		font-size: $control-font-size;
		line-height: 1; // line-height:1 + vertical-align:top to center the icon
		color: $control-color;
		text-align: center;
		border-radius: 50%;
		transition: background-color 150ms ease-in-out;

		&:hover {
			background: $control-hover-bg-color;
		}

		i {
			vertical-align: top; // line-height:1 + vertical-align:top to center the icon
		}
	}

	&-recording-main {
		display: flex;
		min-height: 60px;
	}

	&-waveform-container {
		position: relative;
		flex: 1;
	}

	&-event-point {
		@include reset-button();

		position: absolute;
		top: 0;
		z-index: $wavesurfer-cursor-z-index;
		display: block;
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 50%;
		box-shadow: $container-shadow;
		transform: translate(-50%, -50%);

		&.note {
			background: $blue;
		}

		&.poi {
			background: $yellow;
		}

		&.inappropriate {
			background: $red;
		}
	}

	&-waveform {
		//background-color: $gray-100;
	}

	&-volume-controls {
		display: flex;
		flex-direction: column;
	}

	&-volume-control-container {
		flex: 1;
	}

	&-controls-bottom {
		display: flex;
		flex-wrap: wrap;
		align-content: space-evenly;
		align-items: center;
		height: 6rem;
		font-size: 0.8125rem;
		font-weight: 500;

		@include media-breakpoint-up(md) {
			height: 4rem;
		}
	}

	&-event-toggle {
		margin-right: 1rem;
		display: flex;
		align-items: center;
	}

	&-spacer {
		flex: 1;
	}

	&-playback-speed {
		display: inline-flex;

		&-label {
			cursor: default;
			user-select: none;
		}

		&-option {
			@include reset-button();

			padding: 0 0.5rem;
			overflow: hidden;
			transition:
				width 150ms ease-out,
				padding 150ms ease-out;

			&.selected {
				color: $primary;
			}

			&.unavailable {
				width: 0;
				padding: 0;
			}
		}
	}
}
