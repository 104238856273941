@import "scss/theme";

.card-container {
	display: flex;
	//flex-wrap: wrap;
	align-content: flex-start;
	text-align: center;

	.card {

		background-color: transparent;
		flex: 1;
		//flex-basis: 50%;
		display: block;
		border: none;
		padding: 5px 0;
		text-decoration: none;

		img {
			padding-bottom: 5px;
			padding-left: 5px;
		}

		.card-text {
			font-size: 0.8rem;
			font-weight: bold;
			color: $body-color;
		}

		&:hover {
			background-color: theme-color("secondary");
		}
	}
}
