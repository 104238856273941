
section.register {

	.list-group {
		display: inline-block;
		list-style-position: inside;

		.list-group-item {
			text-align: left;
			border: 0;
			display: list-item;
			padding-top: 0;
		}
	}
}
