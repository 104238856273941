@import "scss/theme";

.browser-extension-button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid theme-color("notice");
	cursor: pointer;
	flex-basis: 75%;

	&.smaller {
		flex-basis: 30%;
	}

	img {
		height: 45px;
	}
}
a.browser-extension-button,
a.browser-extension-button:hover {
	text-decoration:none !important;
	color: $gray-900 !important;
}

.button-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 1.5rem 0 1.5rem;

	.btn {
		flex-basis: 75%;
	}
}
