@import 'scss/theme';

.public-layout {

	.footer {

		position: relative;
		background-color: $gray-400;

		.tick {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 35px;
			background: url('../../../../images/footer/footer-tick.png') no-repeat center top;
		}

		[class^="container"],
		[class*=" container"] {

			background-color: $gray-400;
			background-image: url(../../../../images/footer/footer.jpg);
			background-repeat: no-repeat;
			background-position: left bottom;
			background-attachment: fixed;
		}

		.underline-wavy {
			padding: 20px;
			text-underline-offset: -0.75rem;
			background-color: #eaeaea;
			z-index: 1;
			position: relative;
		}

		hr {
			margin-top: -20px;
		}
	}
}
