.app {
    flex: 1 auto;

    @media (max-width: $mantine-breakpoint-xs) {
        flex: 1 100%;
    }
}

.button {

    @media (max-width: $mantine-breakpoint-xs) {
        flex: 1 auto;
        justify-content: center;
    }
}
