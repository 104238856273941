.bullet {
	border-radius: 50%;
	padding: 3px;
	display: inline-block;
	border: 1px solid black;

	&-active {
		background-color: black;
	}
}
