@import 'scss/theme';

.search-box {

	.btn-close {
		padding: 0;
		margin: 0.5rem;
	}
}

.search-box.alert-dismissible {
	padding: 1.5rem;
}
