.popover {
	border: none;
	border-top: 1px solid $gray-100;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	width: $popover-max-width;
}

.popover-body {

	.provider-logo {
		height: 30px;
	}

	.contact-open {
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	.contact {
		.contact-audio-call {
			visibility: hidden;

			.bif {
				display: inline;
			}

			.bif-text {
				margin-left: 4px;
				vertical-align: top;
			}
		}

		&:hover {
			background-color: $gray-100;

			.contact-audio-call {
				visibility: visible;
			}
		}
	}
}
