/* Red */
$red-dark: #b20000;

/*  Yellow  */
$yellow-dark: #f5ad06;

/*  Blue  */
$blue-dark: #4b8dd8;

/*  Green  */
$green-dark: #5f9e41;

$container-shadow: 0 0.1875rem 0.625rem rgba($black, 0.1);

$separator-color: $gray-100;

$wavesurfer-cursor-z-index: 3;

$border-radius-main: 0.25rem;

$badge-pill-padding-x: .6em !default;

$card-deck-margin: 15px !default;
